<template lang="pug">
    #permission-explainer(v-esc-close="cancel")
      .modal-header
        h2 Why do we need this?
      .modal-text
        p We ask for access to your microphone and camera to help you collect samples for the Kit. We will video-chat with you to guide you through the Kit process and use video recording to verify that it is you completing the Kit.
      .modal-footer
        div
          button.button.action-btn(@click="close")
            span Continue
          p.continue-without-video(v-if="allowProceedWithoutVideo" @click="openConfirmationModal") Continue without video
  </template>

<script>
export default {
  props: {
    onAction: { type: Function, required: false },
    allowProceedWithoutVideo: { type: Boolean, default: false },
  },

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {
    proceedWithoutVideo,
    cancel,
    close,
    openConfirmationModal,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function cancel() {
  this.cancel()
}

function close() {
  this.$modal.close('PermissionExplainer')
}

function proceedWithoutVideo() {
  this.onAction()
  this.$modal.closeAll()

}

function openConfirmationModal() {
  this.$modal.open('Warning', {
    warningTitle: 'Are you sure you want to proceed without video?',
    warningText: 'If you decide to proceed without using your camera, our agents will not be able to assist you.',
    ackBtnText: 'Proceed Without Video',
    canClose: false,
    cancelBtn: true,
    callBack: this.proceedWithoutVideo,
    buttonType: 'critical',
  })
}

</script>
